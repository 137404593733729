import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/produkty/sudafed_xylospray/" className="sel">
              {" "}
              &gt; SUDAFED® XYLOSPRAY
            </a>
          </div>
          <div id="page_content">
            <div className="col2">
              <div className="product_content">
                <div className="product_name">
                  <div className="info">
                    <p className="name">
                      SUDAFED<sup>®</sup> <br />
                      XYLOSPRAY
                    </p>
                    <div className="desc" />
                  </div>
                  <div className="photo">
                    <span className="stamp ">
                      <img
                        src="/upload/znaczek_AZ3_v2.png"
                        alt="AZ3"
                        id="az3"
                      />
                      <img src="/upload/10h_znaczek.png" alt="10h" id="h10" />
                      <img
                        src="/upload/krople_znaczek.png"
                        alt="Zawiera kwas hialuronowy"
                        id="kh"
                      />
                      <img
                        src="/upload/zielony.png"
                        alt="Nie zawiera konserwantó"
                        id="nzk"
                      />
                    </span>
                    <img src="/web/kcfinder/uploads/files/produkty/xylospray.png" />
                  </div>
                </div>
                <div className="wysiwyg">
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    Podczas kataru występują trudności z oddychaniem spowodowane{" "}
                    <a
                      href="/slowniczek/#obrzek_blony_sluzowej"
                      target="_blank"
                    >
                      obrzękiem błony śluzowej nosa
                    </a>{" "}
                    oraz nadmierną ilością wydzieliny. Sudafed<sup>®</sup>{" "}
                    XyloSpray szybko odblokowuje nos i zatoki oraz ułatwia
                    usunięcie zalegającej{" "}
                    <a href="/slowniczek/#wydzielina_z_nosa" target="_blank">
                      wydzieliny
                    </a>
                    . Działa do 10 godzin zapewniając swobodny oddech w ciągu
                    dnia i spokojny sen w nocy.&nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <ul>
                    <li
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      Szybko odblokowuje nos i zatoki
                    </li>
                    <li
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      Działa do 10 godzin
                    </li>
                    <li
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      Wysoka jakość w atrakcyjnej cenie*
                    </li>
                  </ul>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    Wskazania i dawkowanie:
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    Preparat wskazany do stosowania podczas krótkotrwałego
                    leczenia stanu{" "}
                    <a
                      href="/slowniczek/#zapalenia_blony_sluzowej_nosa"
                      target="_blank"
                    >
                      zapalnego błony śluzowej nosa
                    </a>{" "}
                    w przebiegu przeziębienia lub{" "}
                    <a href="/slowniczek/#alergia" target="_blank">
                      alergii
                    </a>
                    . Ułatwia odpływ wydzieliny w zapaleniu zatok przynosowych
                    oraz w zapaleniu{" "}
                    <a href="/slowniczek/#trabka_sluchowa" target="_blank">
                      trąbki słuchowej ucha
                    </a>
                    &nbsp;środkowego połączonego z{" "}
                    <a href="/slowniczek/#przeziebienie" target="_blank">
                      przeziębieniem
                    </a>
                    .
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    Przeznaczony jest do stosowania u dorosłych oraz dzieci
                    powyżej 6 roku życia. W zależności od potrzeb, jeśli lekarz
                    nie zalecił innego sposobu dawkowania, należy stosować po
                    jednej dawce do każdego otworu nosowego, trzy razy w ciągu
                    doby, maksymalnie przez siedem dni.&nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    Sudafed<sup>®</sup> XyloSpray można używać do 24 tygodni po
                    otwarciu butelki.
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      UWAGA!
                    </span>
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      Aby zminimalizować ryzyko rozprzestrzeniania się zakażeń,
                      opakowanie leku nie powinno być stosowane przez więcej niż
                      jedną osobę, a aplikator należy opłukać gorącą wodą po
                      każdym użyciu.
                    </span>
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      * najtańszy aerozol spośród wszystkich aerozoli marki
                      Sudafed®
                    </span>
                  </div>
                  <div>&nbsp;</div>
                </div>
                <a
                  href="/web/kcfinder/uploads/files/pdf/Sudafed XyloSpray_SmPC_04.2021.pdf"
                  className="pdf btn_link"
                  target="_blank"
                >
                  Przeczytaj ulotkę
                </a>
              </div>
            </div>
            <div className="col1">
              <div className="related">
                <p className="name">Dowiedz się więcej</p>
                <div className="line">
                  <a href="/poradnik/katar/jak_prawidlowo_oczyszczac_nos_podczas_kataru/">
                    <div className="c1">
                      <span className="photo" style={{"background-image":"url('/web/kcfinder/uploads/files/artykuly/jak_prawidlowo_oczyszczac_nos.png')"}} />
                    </div>
                    <div className="c2">
                      Jak prawidłowo oczyszczać nos podczas kataru?{" "}
                    </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/poradnik/katar/poznaj_rodzaje_kataru/">
                    <div className="c1">
                      <span className="photo" style={{"background-image":"url('/web/kcfinder/uploads/files/artykuly/poznaj_rodzaje_kataru.png')"}} />
                    </div>
                    <div className="c2">Poznaj rodzaje kataru </div>
                  </a>
                </div>
              </div>
              <a href="/dobierz-lek/" className="link_box link">
                <img src="/web/kcfinder/uploads/files/box/zrobtest.png" />
              </a>
              <a href="/reklama-telewizyjna/" className="link_box video">
                <img src="/web/kcfinder/uploads/files/box/SudafedHAthumbnail.png" />
              </a>
              <a href="/produkty/sudafed_xylospray/" className="quiz_btn" />
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px"
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow"> Ustawienia plików Cookie </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty. 2023
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
